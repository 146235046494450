<template>
  <div class="w-full flex flex-col gap-10">
    <div class="flex justify-between items-end md:items-center">
      <div class="flex gap-2 flex-wrap">
        <simple-flag
          @click="changeTab('aprovacao')"
          :selected="tabSelected === 'aprovacao'"
          text="Aprovação pós plantão"
        />
        <simple-flag
          @click="changeTab('relatorios')"
          :selected="tabSelected === 'relatorios'"
          text="Relatórios"
        />
      </div>
      <v-btn
        @click="toggleFilterModal"
        class="font-bold capitalize"
        slim
        color="primary"
        :prepend-icon="mdiFilter"
        variant="text"
      >
        Filtrar
      </v-btn>
    </div>
    <div class="w-full flex justify-between flex-wrap md:flex-nowrap items-center gap-8">
      <div class="w-full" v-show="tabSelected === 'relatorios'">
        <Reports />
      </div>
      <div class="w-full" v-show="tabSelected === 'aprovacao'">
        <TableApprovalsShifts />
      </div>
    </div>
  </div>
  <teleport to="#app">
    <FilterStationModal />
  </teleport>
</template>

<script lang="ts">
import { ref } from 'vue'
import SimpleFlag from '@/components/designSystem/SimpleFlag/SimpleFlag.vue'
import Reports from '@/modules/company/Management/reports/Index.vue'
import TableApprovalsShifts from './approveStations/index.vue'
import { mdiFilter } from '@mdi/js'
import FilterStationModal from '@/components/Filters/filter.stations.vue'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'

export default {
  components: {
    SimpleFlag,
    Reports,
    TableApprovalsShifts,
    FilterStationModal
  },
  setup() {
    const modalStore = useModalStore()
    const toggleFilterModal = () => {
      modalStore.toggleModal(EModals.FILTER_STATION)
    }

    const tabSelected = ref('aprovacao')
    const changeTab = (val: string) => {
      tabSelected.value = val
    }

    return {
      tabSelected,
      changeTab,
      mdiFilter,
      toggleFilterModal
    }
  }
}
</script>

<style>
.highlight-icon:hover {
  background: #d8eef3;
}
</style>
