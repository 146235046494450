<template>
  <svg
    v-if="solid"
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15" :fill="color" :stroke="color" stroke-width="2" />
    <rect width="19.2" height="19.2" transform="translate(6.3999 6.39996)" :fill="color" />
    <path d="M9.94678 16.16H21.7782" stroke="white" stroke-width="2" stroke-linecap="round" />
    <path
      d="M15.8625 21.4399L15.8625 10.3999"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
  <svg
    v-else
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15" fill="white" :stroke="color" stroke-width="2" />
    <rect width="19.2" height="19.2" transform="translate(6.40002 6.39999)" fill="white" />
    <path d="M9.94702 16.16H21.7784" :stroke="color" stroke-width="2" stroke-linecap="round" />
    <path d="M15.8627 21.44L15.8627 10.4" :stroke="color" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    solid: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#2F7F91'
    },
    width: {
      type: String,
      default: '32'
    },
    height: {
      type: String,
      default: '32'
    }
  }
}
</script>
