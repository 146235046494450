<template>
  <div class="w-full flex flex-col gap-10">
    <legends-group />
    <breadcrumb-vue title="Minhas Escalas" subtitle="Gerenciar Escala" />
    <Schedule />
  </div>
</template>
<script lang="ts">
import LegendsGroup from '@/components/LegendsGroup/index.vue'
import BreadcrumbVue from '@/components/ManageScale/Breadcrumb.vue'
import Schedule from '@/components/ManageScale/Schedule/index.vue'

export default {
  components: {
    LegendsGroup,
    BreadcrumbVue,
    Schedule
  }
}
</script>
<style></style>
