<template>
  <div class="flex flex-col gap-3 justify-center">
    <div class="flex flex-wrap gap-5">
      <legend-table legendColor="alert" text="Aberta" />
      <legend-table legendColor="default" text="Semanal" />
      <legend-table legendColor="warning" text="Cobertura" />
      <legend-table legendColor="success" text="Extra" />
    </div>
    <div class="flex flex-wrap gap-5">
      <legend-table
        :legendIcon="mdiClockAlertOutline"
        legendColor="neutral"
        text="Não confirmado"
      />
      <legend-table :legendIcon="mdiTextBoxCheck" legendColor="warning" text="Possui observações" />
      <legend-table :legendIcon="mdiAccountAlert" legendColor="primary" text="Médico pendente" />
    </div>
  </div>
</template>
<script>
import LegendTable from '@/components/others/Legend.vue'
import { mdiTextBoxCheck, mdiAccountAlert, mdiClockAlertOutline } from '@mdi/js'

export default {
  components: {
    LegendTable
  },
  setup() {
    return {
      mdiTextBoxCheck,
      mdiAccountAlert,
      mdiClockAlertOutline
    }
  }
}
</script>
