<template>
  <div class="flex justify-start text-[12px] font-normal">
    <span>
      {{ subtitle }} / <span class="font-bold">{{ title }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    subtitle: String,
    title: String
  }
}
</script>

<style></style>
