<template>
  <modal-vue @close="handleToggleModal" :modalActive="modals[modalName]">
    <template #header>
      <p class="font-bold">Editar escala</p>
    </template>
    <div class="flex flex-col">
      <v-text-field
        v-model="data.scheduleName"
        label="Nome da Escala*"
        class="w-full"
        color="primary20"
        variant="outlined"
        :errorMessages="errors.scheduleName"
      />

      <v-autocomplete
        v-model="data.healthUnitId"
        class="w-full"
        color="primary20"
        label="Buscar Unidade *"
        theme="light"
        :items="listHealthUnits"
        variant="outlined"
        :errorMessages="errors.healthUnitId"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="item?.raw?.subtitle"
          ></v-list-item>
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-model="data.timezone"
        class="w-full"
        color="primary20"
        label="Fuso Horário"
        theme="light"
        variant="outlined"
        :items="timezones"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="item?.raw?.subtitle"
          ></v-list-item>
        </template>
      </v-autocomplete>
    </div>
    <template #footer>
      <div class="flex justify-between gap-2 w-full">
        <outline-button width="119px" @click="handleToggleModal" text="Cancelar" />
        <filled-button
          :disabled="isLoading"
          :loading="isLoading"
          text="Editar"
          width="119px"
          @click="handleSubmit"
        />
      </div>
    </template>
  </modal-vue>
</template>

<script lang="ts">
import { ref, PropType, onUpdated } from 'vue'
import { storeToRefs } from 'pinia'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { ScheduleResponse } from '@/Network/Types/Responses/ScheduleUnitResponses'
import ModalVue from '@/components/designSystem/modals/Modal.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { timezones } from '@/hooks/useCommon'
import { useToast } from 'vue-toastification'
export default {
  components: {
    ModalVue,
    OutlineButton,
    FilledButton
  },
  props: {
    schedule: { required: false, type: Object as PropType<ScheduleResponse> }
  },
  setup(props) {
    const modalName = EModals.UPDATE_SCHEDULE
    const scheduleStore = useScheduleStore()
    const modalStore = useModalStore()
    const toast = useToast()
    const healthUnitStore = useHealthUnitStore()
    const { modals } = storeToRefs(useModalStore())

    const { listHealthUnits } = storeToRefs(useHealthUnitStore())

    const data = ref<{ timezone?: string; scheduleName?: string; healthUnitId?: string }>({})
    const errors = ref<{ timezone: string; scheduleName: string; healthUnitId: string }>({
      healthUnitId: '',
      scheduleName: '',
      timezone: ''
    })

    const isSuccess = ref(false)
    const isLoading = ref(false)

    onUpdated(() => {
      if (!props.schedule?.healthUnit?.id) return
      clearErrs()
      data.value = {
        healthUnitId: String(props.schedule.healthUnit.id),
        scheduleName: props.schedule?.name,
        timezone: props.schedule?.timezone
      }
    })

    const hasError = () => {
      let hasError = false
      if (!data.value.scheduleName) {
        errors.value.scheduleName = 'Informe um nome para escala'
        hasError = true
      }
      if (!data.value.healthUnitId) {
        errors.value.healthUnitId = 'Selecione um hospital'
        hasError = true
      }

      return hasError
    }

    const handleToggleModal = () => {
      modalStore.toggleModal(modalName)
    }

    const handleSubmit = async () => {
      if (hasError()) {
        toast.error('Dados inválidos ou não preenchidos')
        return
      }

      try {
        isLoading.value = true

        const healthUnitId = data.value.healthUnitId ?? ''
        const scheduleName = data.value.scheduleName ?? ''
        const timezone = data.value.timezone ?? ''
        const payload = {
          healthUnitId: Number(healthUnitId),
          payload: { name: scheduleName, timezone },
          scheduleId: props.schedule?.id ?? 0
        }
        await scheduleStore.update(payload)
        await scheduleStore.getAllSchedules(true)
        await healthUnitStore.getAllHealthUnits(true)

        modalStore.alertDescription = 'Escala editada com sucesso!'
        modalStore.toggleModal(modalName)
        modalStore.toggleShowAlert()
      } finally {
        isLoading.value = false
      }
    }

    const clearErrs = () => {
      errors.value.healthUnitId = ''
      errors.value.scheduleName = ''
      errors.value.timezone = ''
    }

    return {
      modalName,
      modals,
      listHealthUnits,
      timezones,
      data,
      errors,
      isSuccess,
      isLoading,
      handleToggleModal,
      handleSubmit
    }
  }
}
</script>
