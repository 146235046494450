<template>
  <div class="flex flex-col gap-2 w-full">
    <div v-for="unit of healthUnits?.data" :key="unit.id" class="flex w-full flex-col">
      <div class="flex w-full">
        <span
          @click="toggleScales(unit.id)"
          class="header-title"
          :class="showScales[unit.id] ? 'rounded-b-none' : ''"
        >
          <div class="flex justify-start text-left">
            <span class="font-normal"
              >Empresa {{ unit.company.name.toLocaleLowerCase() }}&nbsp;|&nbsp;</span
            >
            Unidade {{ unit.name }}
          </div>
          <arrow-icon :class="showScales[unit.id] ? 'rotate-180' : ''" color="#000" />
        </span>
      </div>
      <template v-if="showScales[unit.id]">
        <div v-for="schedule of unit.schedules" :key="schedule.id" class="card">
          <div class="flex flex-col text-left">
            <span class="capitalize">{{ schedule.name.toLocaleLowerCase() }}</span>
            <div class="flex flex-wrap md:gap-2 items-center text-gray-500 text-sm">
              <span>{{ getTimezoneDetails(schedule.timezone)?.title }}</span>
              <span>({{ getTimezoneDetails(schedule.timezone)?.subtitle }})</span>
            </div>
          </div>
          <span class="flex gap-1">
            <edit-icon
              @click="handleUpdate({ ...schedule, healthUnit: { id: unit.id, name: unit.name } })"
              class="cursor-pointer"
            />
            <delete-icon @click="handleRemove(schedule.id)" class="cursor-pointer" />
          </span>
        </div>
        <div class="w-full p-2 rounded-b-md bg-[#FAFAFA]"></div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import ArrowIcon from '@/assets/icons/down.vue'
import EditIcon from '@/assets/icons/edit.vue'
import DeleteIcon from '@/assets/icons/delete.vue'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { timezones } from '@/hooks/useCommon'
import { EModals, useModalStore } from '@/pinia-store/useModalStore'
import { ScheduleResponse } from '@/Network/Types/Responses/ScheduleUnitResponses'

export default {
  components: {
    ArrowIcon,
    EditIcon,
    DeleteIcon
  },
  setup(_, { emit }) {
    const healthUnitStore = useHealthUnitStore()
    const modalStore = useModalStore()
    const showScales = ref<Record<string, boolean>>({})
    const { healthUnits } = storeToRefs(useHealthUnitStore())

    onMounted(async () => {
      await healthUnitStore.getAllHealthUnits()
      healthUnits.value?.data.forEach((h) => {
        showScales.value[h.id] = true
      })
    })

    const toggleScales = (id: number) => {
      showScales.value = { ...showScales.value, [id]: !showScales.value[id] ?? true }
    }

    const getTimezoneDetails = (time: string) => {
      return timezones.find((t) => t.value === time)
    }

    const handleRemove = (id: number) => {
      emit('removeSchedule', id)
      modalStore.toggleModal(EModals.REMOVE_SCHEDULE)
    }

    const handleUpdate = (schedule: ScheduleResponse) => {
      emit('updateSchedule', schedule)
      modalStore.toggleModal(EModals.UPDATE_SCHEDULE)
    }

    return {
      showScales,
      toggleScales,
      healthUnits,
      getTimezoneDetails,
      handleRemove,
      handleUpdate
    }
  }
}
</script>

<style scoped>
.header-title {
  @apply flex justify-between items-center gap-4 w-full px-[12px] py-[16px] rounded-lg bg-[#FAFAFA] text-black text-[16px] font-bold cursor-pointer capitalize;
}
.card {
  @apply w-full flex flex-wrap gap-4 items-center md:justify-between px-[12px] py-[10px] bg-[#FAFAFA];
}
.card:hover {
  @apply bg-gray-200;
}
</style>
