import * as yup from 'yup'
import { format, addDays, isBefore, setMinutes, setHours } from 'date-fns'
import {
  HistoryDate,
  HistoryDateRequest,
  Observation
} from '@/Network/Types/Requests/StationRequests'

export interface StationToUpdate {
  stationId: number
  companyId: number
  doctorId?: number
  date: Date
  startHour?: Date
  endHour?: Date
  timezone: string
  stationType: string
  ourTeam: boolean
  inCash: boolean
  advanceRequested: boolean
  advancePaid: boolean
  modifiedValue?: boolean
  value: number
  checks: HistoryDate[]
  observations: Observation[]
  doctorPending?: boolean
  urlConviteDoctor?: string
  stationValue: number
}

export const optionsRepeatStation = [
  { title: 'Domingo', value: '0' },
  { title: 'Segunda-feira', value: '1' },
  { title: 'Terça-feira', value: '2' },
  { title: 'Quarta-feira', value: '3' },
  { title: 'Quinta-feira', value: '4' },
  { title: 'Sexta-feira', value: '5' },
  { title: 'Sábado', value: '6' }
]

export const finishedAtOptions = [
  { title: 'Não repetir', value: 'never' },
  { title: 'Para sempre', value: 'always' },
  { title: 'Até', value: 'until' },
  { title: 'Após', value: 'occurrence' }
]

export const StationSchema = yup.object({
  // SCHEDULE SECTION
  company: yup.number().integer().nullable(),
  healthUnit: yup.number().integer().nullable(),
  schedule: yup.number().integer().nullable(),
  sponsorDoctorId: yup.number().integer().optional(),
  // DATE SECTION
  date: yup.string().required(),
  startHour: yup.string().required(),
  endHour: yup.string().required(),
  timezone: yup.string().required(),
  configurationRepetion: yup.string().oneOf(['no-repeat', 'daily', 'custom']).required(),
  valueRepeation: yup.array().when('configurationRepetion', {
    is: 'custom',
    then: yup.array().min(1).required(),
    otherwise: yup.number().nullable().optional()
  }),
  finishedAt: yup.string().oneOf(['never', 'until', 'occurrence', 'always']).required(),
  finishedAtUntil: yup.date().optional(),
  finishedAtAfter: yup.number().when('finishedAt', {
    is: 'occurrence',
    then: yup.number().integer().min(1).required(),
    otherwise: yup.number().nullable().optional()
  }),
  // INFO SECTION
  ourTeam: yup.boolean().required(),
  stationType: yup.string().required(),

  // VALUES SECTION
  doctorId: yup.number().integer().optional(),
  inCash: yup.boolean().required(),
  advanceRequested: yup.boolean().required(),
  advancePaid: yup.boolean().required(),
  urlConviteDoctor: yup.string().optional(),
  value: yup.number().optional(),

  // OBSERVATION SECTION
  observations: yup
    .array()
    .of(
      yup.object({
        date: yup.string().optional(),
        description: yup.string().min(1).required(),
        userName: yup.string().optional()
      })
    )
    .required()
})

export type Station = yup.InferType<typeof StationSchema>
export type ErrorStation = { [K in keyof Station]: string }

export const defaultValues = {
  company: null,
  sponsorDoctorId: undefined,
  doctorId: undefined,
  startHour: format(new Date(), 'HH:mm'),
  endHour: format(new Date(), 'HH:mm'),
  timezone: '-03:00',
  date: format(new Date(), 'dd/MM/yyyy'),
  configurationRepetion: 'no-repeat',
  finishedAt: 'never',
  finishedAtAfter: undefined,
  finishedAtUntil: new Date(),
  healthUnit: null,
  ourTeam: true,
  value: undefined,
  schedule: undefined,
  stationType: 'SEMANAL',
  valueRepeation: undefined,
  inCash: false,
  advanceRequested: false,
  urlConviteDoctor: "#",
  advancePaid: false,
  observations: []
}
const values: Station = { ...defaultValues }

export const states = {
  values
}

export const getDate = ({
  date,
  hours,
  timezone = '-03:00'
}: {
  date: string
  hours: string
  timezone?: string
}): Date => {
  const [day, month, year] = date.split('/')
  const [hour, minute] = hours.split(':')
  const formatedDate = `${year}-${month}-${day}T${hour}:${minute}:00.000${timezone}`
  return new Date(formatedDate)
}

export const getStartDateAndEndDate = ({
  date,
  startHour,
  endHour,
  timezone = '-03:00'
}: {
  date: string
  startHour: string
  endHour: string
  timezone?: string
}): { startDate: Date; endDate: Date } => {
  const startDate = getDate({ date, hours: startHour, timezone })
  const endD = getDate({ date, hours: endHour, timezone })
  const endDate = isBefore(endD, startDate) ? addDays(endD, 1) : endD
  const d = { startDate, endDate }
  return d
}

export const hourDate = (data: Date, text: string) => {
  const hour = Number(text.split(':')[0])
  const minutes = Number(text.split(':')[1])
  const res = setMinutes(setHours(data, hour), minutes)
  return res
}

export const getHistoriesRequest = (
  data: HistoryDate[],
  startDate: Date,
  endDate: Date
): HistoryDateRequest[] => {
  const payload = data.map((c): HistoryDateRequest => {
    let checkin = c.checkin
    let checkout = c.checkout
    if (c.checkin.split('T').length > 1) {
      const hours = new Date(c.checkin).getHours()
      const minutes = new Date(c.checkin).getMinutes()
      checkin = `${hours}:${minutes}`
    }

    if (c.checkout && c.checkout.split('T').length > 1) {
      const hours = new Date(c.checkout).getHours()
      const minutes = new Date(c.checkout).getMinutes()
      checkout = `${hours}:${minutes}`
    }

    return {
      id: c.id,
      checkin: hourDate(startDate, checkin),
      checkout: checkout ? hourDate(endDate, checkout) : undefined,
      origin: c.origin,
      isMain: c.isMain
    }
  })

  return payload
}
