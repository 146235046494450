<template>
  <!-- <div v-if="dashboardView.type == 'day'">
    <div v-for="day of daysOfWeek" :key="day" class="weekDay card dayViewHeader">
      {{ day }}
    </div>
    <div v-for="date of datesOfWeek" :key="date" class="weekDay card dayViewHeader">
      {{ date }}
    </div>
    <div class="text-sm overflow-x-auto">
      <template v-for="period of dashboardView.periods" :key="period.title">
        <div class="period text-[14px] flex items-center justify-center font-bold">
          {{ period.title }}
        </div>
        <div class="flex flex-col justify-center content-center">
          <div v-for="card of period.dates" :key="card.id" class="card flex justify-center">
            <standard-row v-if="!card.opened" @click="$emit('openDutyModal')" :tableType="card.cell"
              photo="https://cdn-icons-png.flaticon.com/512/147/147144.png" :crm="card.crm"
              :pendences="card?.type?.pendences" :success="card?.type?.success" :error="card?.type?.error"
              :waiting="card?.type?.waiting" :name="card?.name" />
            <vacancy-row v-else />
          </div>
        </div>
      </template>
    </div>
  </div> -->
  <div v-if="type == 'WEEK'">
    <div class="parent text-sm overflow-x-auto bg-gray-200">
      <!-- Dias da semanha e data do dia -->
      <div class="weekDay"></div>
      <div class="weekDay"></div>

      <div v-for="day of weekTableHead.map((w) => w.label)" :key="day" class="weekDay card">
        {{ day }}
      </div>
      <div class="weekDay"></div>
      <div class="weekDay"></div>
      <div v-for="date of weekTableHead.map((w) => w.value)" :key="date" class="weekDay card">
        {{ date }}
      </div>

      <!-- Fim da data -->
      <template v-for="company of weekData?.data" :key="company.companyName">
        <template v-for="unity of company.healthUnities" :key="unity.healthUnitName">
          <div class="period text-[14px] flex gap-2 items-center justify-center h-12 bg-gray-100">
            <span>{{ company.companyName }}</span
            ><span>-</span><span class="font-bold">{{ unity.healthUnitName }}</span>
          </div>

          <template v-for="scale of unity.schedules" :key="scale.scheduleId">
            <div
              class="flex flex-col justify-center items-center font-bold bg-white border-1"
              :class="`row-span-${scale.hours.length}`"
            >
              {{ scale.scheduleName }}
              <span class="text-xs font-normal">{{
                getTimezoneDetails(scale.scheduleTimezone)?.subtitle
              }}</span>
            </div>
            <template v-for="hour of scale.hours" :key="hour.title">
              <div class="flex flex-col justify-center items-center font-bold bg-white text-xs">
                <span class="m-0">
                  {{ hour.title }}
                </span>
              </div>
              <div v-for="(vacancy, vacancyIndex) of hour.stations" :key="vacancyIndex">
                <div
                  :class="[
                    `${vacancy.length > 1 && cardIndex !== vacancy.length - 1 ? 'mb-[2px]' : ''}`,
                    'cursor-pointer border-transparent border-0 hover:border-2 hover:border-gray-400 hover:shadow-xl hover:scale-110'
                  ]"
                  v-for="(card, cardIndex) of vacancy"
                  :key="cardIndex"
                >
                  <standard-row
                    v-if="!card.opened"
                    @click="handleClickStation(company.companyId, card)"
                    :tableType="card.cell"
                    photo="https://cdn-icons-png.flaticon.com/512/147/147144.png"
                    :crm="card.crm"
                    :pendences="card?.type?.pendences"
                    :success="card?.type?.success"
                    :error="card?.type?.error"
                    :waiting="card?.type?.waiting"
                    :hasObservations="!!card?.observations.length"
                    :doctorPending="!!card?.doctorPending"
                    :doctorConfirmed="!!card?.doctorConfirmed"
                    :name="card?.doctorName"
                  />
                  <vacancy-row v-else @click="handleClickStation(company.companyId, card)" />
                </div>
              </div>
            </template>
          </template>
          <!-- <template  v-for="(vacancy, vacancyIndex) of hour.vacancies" :key="vacancyIndex">
                <div v-for="(card, cardIndex) of vacancy" :key="cardIndex">
                  <standard-row
                    v-if="!card.opened"
                    @click="$emit('openDutyModal')"
                    :tableType="card.cell"
                    photo="https://cdn-icons-png.flaticon.com/512/147/147144.png"
                    :crm="card.crm"
                    :pendences="card?.type?.pendences"
                    :success="card?.type?.success"
                    :error="card?.type?.error"
                    :waiting="card?.type?.waiting"
                    :name="card?.name"
                  />
                  <vacancy-row v-else />
                </div>
              </template> -->
          <!-- <div v-for="(scale, scaleIndex) of unity.scales" :key="scaleIndex">
          <div class="flex justify-center items-center font-bold bg-white border-1">{{scale.title}}</div>
          <div v-for="card of week" :key="card.id" class="card">
          </div>
          <div v-for="card of week" :key="card.id" class="card">
            <standard-row
              v-if="!card.opened"
              @click="$emit('openDutyModal')"
              :tableType="card.cell"
              photo="https://cdn-icons-png.flaticon.com/512/147/147144.png"
              :crm="card.crm"
              :pendences="card?.type?.pendences"
              :success="card?.type?.success"
              :error="card?.type?.error"
              :waiting="card?.type?.waiting"
              :name="card?.name"
            />
            <vacancy-row v-else />
          </div>
        </div> -->
        </template>
      </template>
    </div>
  </div>
  <!-- <div v-if="dashboardView.type == 'month'">
    <div class="parent text-sm overflow-x-auto">
      <div v-for="day of daysOfWeek" :key="day" class="weekDay card">
        {{ day }}
      </div>
      <div v-for="date of datesOfWeek" :key="date" class="weekDay card">
        {{ date }}
      </div>
      <template v-for="period of dashboardView.periods" :key="period.title">
        <div class="period text-[14px] flex items-center justify-center font-bold">
          {{ period.title }}
        </div>
        <div v-for="(day, index) in monthRange.range" :key="day" class="card bg-white min-h-[70px]">
          <div v-if="day > 0">
            <div>{{ day }}</div>
            <div v-for="card of period.dates[index - monthRange.startDay]" :key="card.id" class="card">
              <MinimalRow v-if="!card.opened" @click="$emit('openDutyModal')" :tableType="card.cell"
                photo="https://cdn-icons-png.flaticon.com/512/147/147144.png" :crm="card.crm"
                :pendences="card?.type?.pendences" :success="card?.type?.success" :error="card?.type?.error"
                :waiting="card?.type?.waiting" :name="card?.name" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div> -->
  <!-- <div v-if="dashboardView.type == 'year'">
    <div class="grid grid-cols-4 w-100 m-0">
      <div v-for="(month, monthIndex) of yearCalendar" :key="month">
        <div class="text-left font-bold mb-2 ml-1.5">
          {{ monthNames[monthIndex] }}
        </div>
        <div class="grid grid-cols-7 gap-4 m-0 mb-2">
          <div class="w-6">S</div>
          <div class="w-6">T</div>
          <div class="w-6">Q</div>
          <div class="w-6">Q</div>
          <div class="w-6">S</div>
          <div class="w-6">S</div>
          <div class="w-6">D</div>
        </div>
        <div class="grid grid-cols-7 gap-4 m-0">
          <div v-for="(day, index) of month.range" :key="index">
            <div @click="(e) => $emit('showYearDayContent', e, monthIndex, day)" :class="`${selectedYearDay.month == monthIndex && selectedYearDay.day == day
              ? 'selectedYearDay'
              : ''
              } w-6 cursor-pointer`" v-if="day > 0">
              {{ day }}
            </div>
            <div v-else></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script lang="ts">
import VacancyRow from '@/components/designSystem/Table/StandardRow/vacancy.vue'
import StandardRow from '@/components/designSystem/Table/StandardRow/withIcon.vue'
import { computed, defineEmits } from 'vue'
import { ListStationsResponse, StationWeekList } from '@/Network/Types/Responses/StationResponses'
import { timezones } from '@/hooks/useCommon'
import { StationToUpdate } from '../modals/createStation/helpers'
interface Item {
  label: string
  value: string
}
export default {
  name: 'DashboardView',
  props: {
    type: {
      type: String,
      default: 'WEEK'
    },
    weekTableHead: {
      type: Array as () => Item[],
      default: () => []
    },
    weekData: {
      type: Object as () => ListStationsResponse,
      default: () => {}
    }
  },
  components: {
    VacancyRow,
    StandardRow
  },
  setup(props, ctx) {
    const createMonthRange = (month) => {
      const date = new Date()
      const y = date.getFullYear()
      const firstDay = new Date(y, month, 1)
      const lastDay = new Date(y, month + 1, 0)
      const range = [...Array(lastDay.getDate()).keys()].map((i) => i + firstDay.getDate())
      const startDay = firstDay.getDay() - 1 > 0 ? firstDay.getDay() - 1 : 0
      for (let i = 0; i < startDay; i += 1) {
        range.unshift(-1)
      }

      return { range, startDay }
    }

    const getTimezoneDetails = (time: string) => {
      return timezones.find((t) => t.value === time)
    }

    // const yearCalendar = computed(() => {
    //   const calendar = []

    //   for (let month = 0; month < 12; month += 1) {
    //     const monthRange = createMonthRange(month)
    //     calendar.push(monthRange)
    //   }

    //   return calendar
    // })
    defineEmits(['openDutyModal', 'showYearDayContent', 'openDutyModal', 'closeModal'])

    const monthRange = computed(() => {
      const date = new Date()
      const month = date.getMonth()
      const { range, startDay } = createMonthRange(month)
      return { range, startDay }
    })

    const handleClickStation = (companyId: number, card: StationWeekList) => {
      const data: StationToUpdate = {
        inCash: card.inCash,
        stationId: card.stationId,
        companyId: companyId,
        doctorId: card.doctorId,
        date: card.date,
        startHour: card.startHour,
        endHour: card.endHour,
        timezone: card.timezone,
        stationType: card.stationType,
        ourTeam: card.ourTeam,
        value: card.value,
        advancePaid: card.advancePaid,
        advanceRequested: card.advanceRequested,
        observations: card.observations,
        modifiedValue: card.modifiedValue,
        doctorPending: card.doctorPending,
        urlConviteDoctor: card.urlConviteDoctor,
        checks: card.checks.map((c) => ({
          ...c,
          checkin: String(c.checkin),
          checkout: c.checkout ? String(c.checkout) : undefined
        })),
        stationValue: card.stationValue
      }
      ctx.emit('openDutyModal', data)
    }

    return {
      monthRange,
      handleClickStation,
      getTimezoneDetails
    }
  }
}
</script>

<style scoped>
.weekDay {
  background: #fafafa;
  padding: 10px;
}

.parent {
  padding: 1px;
  display: grid;
  grid-template-columns: 119px 70px auto auto auto auto auto auto auto;
  grid-column-gap: 2px;
  grid-row-gap: 2px;
}

.period {
  grid-column: 1 / -1;
  padding: 9px;
}

.one-colum {
  grid-column: 1;
  padding: 9px;
}

.days-grid {
  grid-column: 3 / -1;
  padding: 9px;
}

.title-grid {
  grid-column: 1 / 2;
  padding: 9px;
}

.card {
  min-width: 130px;
}

.dayViewHeader {
  border: 1px solid #f0f0f4;
}

.selectedYearDay {
  background: #2f7f91;
  border-radius: 100%;
  color: #fff;
  font-weight: bold;
}

.border-input-time {
  border-bottom: 2px solid #2f7f91;
  border-radius: 4px 4px 0 0;
}
</style>
