<template>
  <h2 class="text-left font-bold text-[18px]">Criar Escala</h2>
  <div class="w-full flex justify-between flex-wrap md:flex-nowrap gap-5">
    <span class="max-w-[1000px] flex flex-wrap gap-8 w-full items-center">
      <v-text-field
        v-model="scheduleName"
        label="Nome da Escala*"
        class="w-[250px]"
        color="primary20"
        variant="outlined"
      ></v-text-field>
      <v-autocomplete
        v-model="healthUnitSelected"
        class="w-[250px]"
        color="primary20"
        label="Buscar Unidade *"
        theme="light"
        :items="listHealthUnits"
        variant="outlined"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="item?.raw?.subtitle"
          ></v-list-item>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-model="scheduleTimezone"
        class="w-[250px]"
        color="primary20"
        label="Fuso Horário"
        theme="light"
        :items="timezones"
        variant="outlined"
      >
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="item?.raw?.title"
            :subtitle="item?.raw?.subtitle"
          ></v-list-item>
        </template>
      </v-autocomplete>
    </span>
    <span
      @click="$emit('openCreateHospitalModal')"
      class="mt-3 min-w-[185px] flex gap-2 text-[16px] cursor-pointer"
    >
      <add-circle-icon /> Cadastrar Hospital
    </span>
  </div>
  <span class="w-full flex justify-end gap-8 mt-10">
    <outline-button @click="router.push('/dashboard')" width="119px" text="Voltar" />
    <filled-button
      @click="createSchedule"
      :disabled="!scheduleName || !healthUnitSelected || isLoading"
      width="143px"
      text="Criar Escala"
      :loading="isLoading"
    />
  </span>
</template>

<script lang="ts">
import AddCircleIcon from '@/assets/icons/add-circle.vue'
import OutlineButton from '@/components/designSystem/Buttons/OutlineButton/index.vue'
import FilledButton from '@/components/designSystem/Buttons/FilledButton/index.vue'
import { useHealthUnitStore } from '@/pinia-store/useHealthUnitStore'
import { useScheduleStore } from '@/pinia-store/useScheduleStore'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { timezones } from '@/hooks/useCommon'

export default {
  components: {
    AddCircleIcon,
    OutlineButton,
    FilledButton
  },
  emits: ['openCreateHospitalModal'],
  setup() {
    const defaultValueTimezone = timezones[1].value
    const router = useRouter()
    const healthUnitStore = useHealthUnitStore()
    const scheduleStore = useScheduleStore()
    const healthUnitSelected = ref('')
    const isLoading = ref(false)
    const { listHealthUnits } = storeToRefs(useHealthUnitStore())

    const healthUnits = ref<{ name: string; id: string }[]>([])
    const scheduleName = ref<string>('')
    const scheduleTimezone = ref<string>(defaultValueTimezone)
    onMounted(async () => {
      const { data: healthUnitsData } = await healthUnitStore.getAllHealthUnits()
      healthUnits.value = healthUnitsData.map((h) => ({ name: h.name, id: String(h.id) }))
    })

    const clearInputs = () => {
      isLoading.value = false
      scheduleName.value = ''
      healthUnitSelected.value = ''
      scheduleTimezone.value = defaultValueTimezone
    }

    const createSchedule = async () => {
      isLoading.value = true
      const data = {
        healthUnitId: Number(healthUnitSelected.value),
        payload: { name: scheduleName.value, timezone: scheduleTimezone.value }
      }
      await scheduleStore.create(data)
      await scheduleStore.getAllSchedules(true)
      await healthUnitStore.getAllHealthUnits(true)
      clearInputs()
    }

    return {
      healthUnits,
      scheduleName,
      scheduleTimezone,
      createSchedule,
      router,
      listHealthUnits,
      healthUnitSelected,
      timezones,
      isLoading
    }
  }
}
</script>

<style></style>
