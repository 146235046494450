import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col gap-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_legends_group = _resolveComponent("legends-group")!
  const _component_breadcrumb_vue = _resolveComponent("breadcrumb-vue")!
  const _component_Schedule = _resolveComponent("Schedule")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_legends_group),
    _createVNode(_component_breadcrumb_vue, {
      title: "Minhas Escalas",
      subtitle: "Gerenciar Escala"
    }),
    _createVNode(_component_Schedule)
  ]))
}