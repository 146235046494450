import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2 text-sm text-textPrimary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.legendIcon)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 0,
          icon: _ctx.legendIcon,
          color: _ctx.legendColor
        }, null, 8, ["icon", "color"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["w-4 h-4 rounded-full", _ctx.defineBgColor()])
        }, null, 2)),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ]))
}