<template>
  <v-card>
    <v-tabs
      v-model="tab"
      bg-color="white"
      :fixed-tabs="true"
      :center-active="true"
      selected-class="bg-primary text-white"
      density="compact"
    >
      <v-tab class="rounded-none text-gray-600" value="monthly">Mensal</v-tab>
      <v-tab class="rounded-none text-gray-600" value="yearly">Anual</v-tab>
      <v-tab class="rounded-none text-gray-600" value="doctor">Médico</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="monthly">
          <MonthlyReport />
        </v-window-item>

        <v-window-item value="yearly">
          <YearlyReport />
        </v-window-item>

        <v-window-item value="doctor">
          <DoctorReport />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
type TabsOpt = 'monthly' | 'yearly' | 'doctor' | 'doctor-resume'
import { ref } from 'vue'
import MonthlyReport from './pages/MonthlyReport.vue'
import YearlyReport from './pages/YearlyReport.vue'
import DoctorReport from './pages/DoctorReport.vue'

const tab = ref<TabsOpt>('monthly')
</script>

<style scoped>
.tab-navigator {
  border-bottom: 1px solid #d8d8e2;
}
.active-tab {
  border-bottom: 2px solid #2f7f91;
}
</style>
